/** Типы способа покупки. */
export const PurchaseConditions = {
    Undefined: 'Undefined',     // Неизвестный статус
    Immediate: 'Immediate',     // Полная оплата.
    Mortgage: 'Mortgage',       // Ипотека.
    Installment: 'Installment'  // Рассрочка.
} as const;

export const defaultMortgageInitialPaymentPercent = 30; // Первоначальный взнос в процентах по умолчанию
export const defaultPeriodInYears = 10; // Срок в годах по умолчанию (для ипотеки)
export const maxPeriodInYears = 30; // Максимальный срок в годах (для ипотеки)

export const defaultInstallmentInitialPaymentPercent = 50; // Первоначальный взнос в процентах по умолчанию
export const defaultPeriodInMonth = 12; // Срок в месяцах по умолчанию (для рассрочки)
export const maxPeriodInMonth = 36; // Максимальный срок в месяцах (для рассрочки)